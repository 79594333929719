.roccolor{
    stroke: rgba(0, 98, 255, 0.8);  
}
 
.rocline{ 
   fill:none;
    stroke-width: 2;
}
 
.rocarea{
    fill: rgba(196, 196, 196, 0.774);
    fill-rule: evenodd;
}

.diagonal{
    stroke: red;
    opacity: 1;
    fill: none;
    stroke-dasharray: 4px;
    stroke-width: 1.2px;
}

.redchance{
    border: 1px dashed red;
}

.rocdot{
    fill: rgba(53, 53, 53, 0.863) ;
    /* stroke: white; */
}