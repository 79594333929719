.eachunitbox{
    height: 15px;
    width: 24px;
    border: rgb(49, 49, 49) 2px solid;
    border-radius: 2px;
    margin: 0px 3px 3px 3px;
    background: rgba(0, 98, 255, 0.8); 
    -webkit-transition:  background-color ease-in 0.4s;
}

.unitgrey{
    background: rgb(197, 197, 197) ;
   
}

.unitblue{
    background: rgba(0, 98, 255, 0.8); 
}

.layerdiv{
    border: 1.5px dashed rgba(0, 0, 255, 0.589);
    border-radius: 3px;
    width: 33px;;
}

.updatebutton{
    display: inline-block;
    background: rgba(128, 128, 128, 0.463);
    padding: 4px 8px 4px 8px;
    /* border-radius: 4px; */
}
.updatebutton:hover{
    background: rgba(128, 128, 128, 0.763);
}

.updatebutton.disabled{
    opacity: 0.4;
}
.networktitle{
     /* border-bottom: 1px solid grey; */
}

.iotextdata{
    text-orientation: sideways;
    writing-mode: vertical-rl;
    text-align: center;
    border: 2px solid rgba(0, 98, 255, 0.8);
}

.bottlneck{
    border: 2px solid rgba(0, 98, 255, 0.8);
    
}

.latentunittitle{
     
}

.mwc{
    min-width: 50px;
    align-content: center;
    /* border: 1px solid grey; */
}

.encdecbox{
    min-height: 216px;
    padding-left: 8px;
    /* padding-right: 10px; */
}