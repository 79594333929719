// @import 'carbon-components/scss/globals/scss/styles.scss';

 //-------------------------
// 🌍 Global
//-------------------------

$css--font-face: true;
$css--helpers: true;
$css--body: true;
$css--use-layer: true;
$css--reset: true;
$css--typography: true;
$css--plex: true;
 

 

// @import 'carbon-components/scss/globals/scss/feature-flags';
// @import 'carbon-components/scss/globals/scss/vars';
// @import 'carbon-components/scss/globals/scss/colors';
// @import 'carbon-components/scss/globals/scss/theme';
// @import 'carbon-components/scss/globals/scss/mixins';
// @import 'layout';
// @import 'layer';
// @import 'spacing';
@import 'carbon-components/scss/globals/scss/typography';
// @import './vendor/@carbon/elements/scss/import-once/import-once';
// @import 'css--reset';
@import 'carbon-components/scss/globals/scss/css--font-face';
// @import 'css--helpers';
@import 'carbon-components/scss/globals/scss/css--body';
// @import '../grid/grid';


//-------------------------
// 🍕 Components
//-------------------------

@import 'carbon-components/scss/components/dropdown/dropdown';
@import 'carbon-components/scss/components/loading/loading';
@import 'carbon-components/scss/components/modal/modal';
@import 'carbon-components/scss/components/button/button';
@import 'carbon-components/scss/components/checkbox/checkbox';
@import 'carbon-components/scss/components/radio-button/radio-button'; 
@import 'carbon-components/scss/components/toggle/toggle';
@import 'carbon-components/scss/components/search/search'; 
@import 'carbon-components/scss/components/tooltip/tooltip'; 
@import 'carbon-components/scss/components/slider/slider';


@import 'carbon-components/scss/components/combo-box/combo-box';

@import 'carbon-components/scss/components/list-box/list-box';

// @import 'carbon-components/scss/components/copy-button/copy-button';
// @import 'carbon-components/scss/components/file-uploader/file-uploader'; 
// @import 'carbon-components/scss/components/select/select';
// @import 'carbon-components/scss/components/text-input/text-input';
// @import 'carbon-components/scss/components/text-area/text-area';
// @import 'carbon-components/scss/components/number-input/number-input';
// @import 'carbon-components/scss/components/form/form';
// @import 'carbon-components/scss/components/link/link';
// @import 'carbon-components/scss/components/list/list';
// @import 'carbon-components/scss/components/data-table/data-table';
// @import 'carbon-components/scss/components/structured-list/structured-list';
// @import 'carbon-components/scss/components/code-snippet/code-snippet';
// @import 'carbon-components/scss/components/overflow-menu/overflow-menu';
// @import 'carbon-components/scss/components/content-switcher/content-switcher';
// @import 'carbon-components/scss/components/date-picker/date-picker'; 
// @import 'carbon-components/scss/components/multi-select/multi-select';
// @import 'carbon-components/scss/components/notification/inline-notification';
// @import 'carbon-components/scss/components/notification/toast-notification';
// @import 'carbon-components/scss/components/tabs/tabs';
// @import 'carbon-components/scss/components/tag/tag';
// @import 'carbon-components/scss/components/pagination/pagination';
// @import 'carbon-components/scss/components/accordion/accordion';
// @import 'carbon-components/scss/components/progress-indicator/progress-indicator';
// @import 'carbon-components/scss/components/breadcrumb/breadcrumb';
// @import 'carbon-components/scss/components/toolbar/toolbar';
// @import 'carbon-components/scss/components/time-picker/time-picker';
// @import 'carbon-components/scss/components/tile/tile';
// @import 'carbon-components/scss/components/skeleton/skeleton';
// @import 'carbon-components/scss/components/inline-loading/inline-loading';
// @import 'carbon-components/scss/components/pagination-nav/pagination-nav';
 

.bx--checkbox-label-text{
    font-size: 0.9em;
    padding: 0px;
}
.bx--slider-container{
    width: 100%;
}
.bx--tooltip__trigger{
    margin: 0px;
}
.bx--tooltip__label{
    padding: 0px;
}

.maxh16{
    // max-height:  12em;
    overflow: scroll;
}