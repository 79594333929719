.normcolor{
    fill: rgba(0, 98, 255, 0.8); 
    stroke: white;
}
.anormcolor{
 fill:  rgba(255, 170, 0, 0.8);;
 stroke: white;
}

.charttitle{
    font-size: 1em;
    font-weight: bold; 
    /* border: 1px solid grey; */
    /* background:  #0263ff;  */
    /* color: white; */
    padding: 5px 5px 8px 0px;
    border-bottom: 2px solid #0263ff;
}



.chartlegend{
    position: absolute; 
    border: 1px solid rgb(228, 223, 223);
    background: rgba(248, 248, 248, 0.822);
    border-radius: 3px;
    /* padding-bottom: 5px; */
}
.legendbottomright{
    bottom:0px;
    right:0px;
}

.legendtopleft{
    top: 8px;
    left: 0px;
}

.legendtopright{
    top:8px;
    right:0px;
}

.legendcolorbox{
    height: 10px;
    width: 10px;
}

.themeblue{
    background: rgba(0, 98, 255, 0.8); 
}

.themeorange{
    background:rgba(255, 170, 0, 0.8);
}

.mainchartbox{
    padding: 10px;
    /* border: 1px solid grey; */
    border-radius: 3px;
    margin: 0px 0px 0px 0px;
}
.chartsvg{
    margin-top:0px;
}


.circlebutton{
    background: #054ADA;
    border-radius: 50%;
    border: 3px solid #054ADA;
}

.circlenumber{
    background: #054ADA;
    border-radius: 50%;
    border: 3px solid white; 
    color: white;
    margin: 0px 0px 0px 5px;
    /* height: 25px; */
    width: 26px;
    font-size: 0.8em;
    font-weight: bold;
}

.circlelarge{
    width: 50px;
    height: 50px;
}

.circlesmall {
    width: 40px;
    height: 40px;
}

.circlebutton:hover{ 
    border: 3px solid white;
    animation:  shadow-pulse 0s;
}

.circlebutton.disabled{
    background: grey;
    pointer-events: none;
    border:grey;
}

.divdisabled{
    opacity: 0.5;
    pointer-events: none;
}

.metricvalue{
    font-size: 1.99em;
    /* padding-top: 0.28em; */
    /* min-width: 160px; */
    /* border: 1px solid grey; */
}

.perfmetrics{
 /* border: 1px solid grey; */
 /* opacity: 0.5; */
}

.permetrics.disabled {
    opacity: 0.1;
    pointer-events: none;
}

.metricguage{
    border-left: solid 6px red;
    padding: 7px 10px 6px 10px  ;
}

.axislabel{
    font-size: 0.92em;
    
}

.modelconfigbar{
    /* max-height: 7em; */
    /* border: 1px solid pink; */
}

.maintrainbox{
    /* min-width: 600px; */
}
 

 
.smallhide{
    /* display: none; */
}


.composermaindiv{
    /* min-width: 400px; */
}

.flexcomp{
    flex:3
}

.chartcontainer{
    align-content: start;
}

.smallblueball{
    height: 8px;
    width: 8px;
    border-radius: 5px;
    background: #0263ff;
    /* border: 1px solid green; */
}

.configsectiontitle{ 
    text-orientation: sideways;
    writing-mode: vertical-rl;
    text-align: center;
    /* border: 2px solid rgba(0, 98, 255, 0.8); */
    /* height: 100px; */

}

.resetbox{
    /* border: 1px solid grey; */
    transition: width 0.7s,  opacity 0.7s;
    -webkit-transition: width 0.7s, opacity 0.7s;
    /* width: 34px; */
}

.opacitytransition{
    transition: width 0.7s,  opacity 0.7s;
    -webkit-transition: width 0.7s, opacity 0.7s;
}

.testa{
    border: 1px solid green;
    width:100px;
}
.traincomposerdiv{
    /* min-width: 350px; */
}

.accuracybox{
    min-width: 140px;
}
